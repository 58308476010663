<template>
  <div>
    <h1 class="welcome-message">Hi, {{user}}</h1>
    
    <div class="logo" >
      <h1 class="logo-title" >PF Counsellor Portal</h1>
      <img class="logo-image" src="../assets/HeaderLogo.png"  />
    </div>
  </div>
</template>

<script>
export default {
  name: 'header-component',
  props: ['user']
}
</script>

<style scoped>
.logo {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.logo-title {
  font-weight:300;
  font-family:'open sans';
  font-size:2.5rem;
  font-style: italic;
  text-align:right;
  color:#000;
  padding: 0px 4px 5px 0px;
}

.welcome-message {
  color:#000;
  font-family:'open sans';
  padding-top:2vh;
  padding-left:2vw;
  font-size:2.1rem;
  text-align:left;
  position:absolute;
  font-weight:400
}

.logo-image {
  width: 8.5rem;
}
</style>