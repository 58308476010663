<template>
  <div>
    <form v-on:submit.prevent >
      <div class="imgcontainer">
        <img src="../assets/HeaderLogo.png" alt="Logo" class="avatar" />
        <h1 >PF Counsellor Portal</h1>
      </div>

      <div class="container">
        <label for="uname"><b>Email</b></label>
        <input type="text" placeholder="Enter Email" name="Email" required v-model="user.Email" />
        <label for="psw"><b>Password</b></label>
        <input v-model="user.Password" type="password" required placeholder="Enter Password" />
        <button type="submit" @click="login()" >Login</button>
      </div>

      <div class="container" v-if="passwordCorrect === false && lockedAccount === false && emaildoesnotexist === false">
        <span style="color:red">Password Incorrect! Remaining Attempts </span>
        <span style="color:red">{{4 - remainingAttempts}} </span>
      </div>

      <div class="container" v-if="emaildoesnotexist === true">
        <span style="color:red">Sorry, Email Address not recognised, Please contact the PF Office</span>
      </div>

      <div class="container" v-if="lockedAccount === true">
        <span style="color:red">You have been locked out of your account due to 5 unsuccessful attempts at signing in. Please, contact the PF office to unlock your account</span>
      </div>

      <div class="container-password" style="background-color: #f1f1f1" @click="recoverPassword()" v-if="lockedAccount === false">
        <span class="psw" >Forgot password?</span>
      </div>
    </form>

    <div class="version">
      <h3>V - 1.2.1</h3>
    </div>
  </div>
</template>

<script>
import PFServices from "../services/PFServices.js"

export default {
  name: "login-page",
  data() {
    return {
      user: {
        Email: null,
        Password: null,
      },
      passwordCorrect: true,
      lockedAccount: false,
      emaildoesnotexist: false,
      remainingAttempts: 0,
    }
  },
  methods: {
    login() {      
      PFServices.getLogin(this.user)
      .then(res => {
        console.log('Login C' + res.ValidateMemberLoginResult.CounsellorID)
        console.log('Login S' + res.ValidateMemberLoginResult.SupervisorID)
  
        if (res.ValidateMemberLoginResult.StatusDescription === "User credentials match" && res.ValidateMemberLoginResult.CounsellorID == 0) {
          this.$router.push("HomeSupervisor");
          localStorage.setItem('User', JSON.stringify(res.ValidateMemberLoginResult))
        } else if (res.ValidateMemberLoginResult.StatusDescription === "User credentials match" && res.ValidateMemberLoginResult.SupervisorID === 0) {
          this.$router.push("Home");
          localStorage.setItem('User', JSON.stringify(res.ValidateMemberLoginResult))
        } else if (res.ValidateMemberLoginResult.StatusDescription === "User account locked") {
          this.lockedAccount = true;
        }
        else if (res.ValidateMemberLoginResult.StatusDescription === "Email Address Does Not Exist") {
          this.emaildoesnotexist = true;
        }       
        else {
          this.passwordCorrect = false;
          this.remainingAttempts = res.ValidateMemberLoginResult.FailedLoginAttempts
        }
      })
    },
    
    recoverPassword() {
      this.$router.push("RecoverPassword");
    }
  }
};
</script>

<style scoped >
form {
  border: 3px solid #f1f1f1;
}

input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

h1 {
  color: #000;
  padding-top: 3vh;
  font-weight: normal; 
  font-family:'open sans';
  font-style: italic;

}

.container-password {
  cursor: pointer;
  padding: 16px;

}

button:hover {
  opacity: 0.8;
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  width: 40%;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

.version {
  color: #000;
  position:absolute;
  text-align: center;
  bottom:1vh;
  left: 50vw;
}

@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
}
</style>