<template>
  <div class="side-bar-nav">
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,300" rel="stylesheet" type="text/css">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
    <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />
    
    <ul>
      <h3 @click="displayClients">
        <i v-if="showClients === true" class="fa fa-chevron-down"></i>
        <i v-if="showClients === false" class="fa fa-chevron-right"></i>
        Clients
      </h3>

      <div  v-if="showClients === true">
        <li v-for="client of $attrs.currentClients" :key="client.ClientID"  @click="$emit(`view-client`, client)">{{client.Forename}} {{client.Surname.charAt(0)}} - {{ client.ClientRef }}</li>
      </div>

      <h3 @click="displayPreviousClients">
        <i v-if="showPreviousClients === true" class="fa fa-chevron-down"></i>
        <i v-if="showPreviousClients === false" class="fa fa-chevron-right"></i>
        Previous Clients
      </h3>

      <div v-if="showPreviousClients === true">
        <li v-for="client of $attrs.previousClients" :key="client.ClientID" @click="$emit(`view-client`, client)">{{client.Forename.charAt(0)}}. {{client.Surname.charAt(0)}} - {{ client.ClientRef }}</li>
      </div>

      <h3>
        <a target="_blank" href="https://pfcounselling.org.uk/counsellors/counsellor-area/?autologin_code=iZBR17RcACIlvq613f5pvyA1rJpZ2H8b">
          <i class="fas fa-globe"></i>
          Resources
        </a>
      </h3>
      
      <h3>
        <a target="_blank" href="https://pfcounsellingservice.sharepoint.com/sites/PFClientnotesandResources/Shared%20Documents">
          <i class="fas fa-globe"></i>
          SharePoint
        </a>
      </h3>

      <h3 @click="logout()">
        <i class="fa fa-sign-out"> </i>
        Logout
      </h3>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'side-bar',
  data() {
    return {
      showClients: false,
      showPreviousClients: false,
      user: JSON.parse(localStorage.getItem('User')),
    }
  },

  methods: {
    displayClients() {
      this.showClients = !this.showClients
    },

    displayPreviousClients() {
      this.showPreviousClients = !this.showPreviousClients
    },

    logout() {
      localStorage.removeItem('User');
      localStorage.removeItem('CurrentClients');
      localStorage.removeItem('previousSessions');
      localStorage.removeItem('CurrentClient');
      localStorage.removeItem('PreviousClient');
      this.$router.push('login');
    },
  }, 
}
</script>

<style scoped>
li {
  list-style: none;
  text-align: left;
  cursor:pointer;
}

a {
  cursor:pointer;
  text-decoration: none; 
  color: #666086; 
  font-weight: bold;
  /* font-family: sans-serif; */
}

h3 {
  font-size: 1.4rem;
  color: #666086; 
  cursor:pointer;
  padding: 10px;
  text-align: left;
}

li {
  text-align: left;
  padding: 3px 3px 3px 15px;
  font-size: 1.2rem;
  font-weight: normal;
}
</style>